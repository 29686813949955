import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import DurationPicker from "../ogComponent/durationPicker"; 

export default function Modal({ heading, fields, onSubmit, type }) {
  const { control, handleSubmit, reset, watch, setValue, formState: { errors } } = useForm();
  const [overtimeDuration, setOvertimeDuration] = useState("00:00:00");

  const startDate = watch('start_date');
  const endDate = watch('end_date');

  // Calculate leave days
  useEffect(() => {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1;

      setValue('total_leave_days', diffDays.toString());
    }
  }, [startDate, endDate, setValue]);

  // Reset form with current field values
  useEffect(() => {
    const defaultValues = fields.reduce((acc, field) => {
      acc[field.name] = field.value || '';
      return acc;
    }, {});

    reset(defaultValues);
  }, [fields, reset]);

  const onSubmitForm = (data) => {
    // Transform the form data conditionally based on the `type` prop
    const transformedData = {
      ...data,
      ...(type === "shift" && { time_bound_for_overtime: overtimeDuration }),
      ...(type === "department" && { 
        department: data.department?.value || fields.find(field => field.name === 'department')?.value || ''
      }),
      ...(type === "branch" && { 
        branches: data.branches ? data.branches.map(option => option.value) : [] 
      }),
    };

    onSubmit(transformedData);
    reset();
  };
  
  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header border-bottom-0">
            <h1 className="modal-title fs-5" id="staticBackdropLabel">
              {heading}
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form onSubmit={handleSubmit(onSubmitForm)}>
            <div className="modal-body d-flex flex-column">
              {fields.map((field, index) => (
                <div key={index} className="mb-3">
                  <label htmlFor={field.name} className="form-label">{field.label}</label>
                  <Controller
                    name={field.name}
                    control={control}
                    defaultValue={field.value || ''}
                    rules={{ required: field.required }}
                    render={({ field: controllerField }) => (
                      <>
                        {field.type === 'select' ? (
                          <Select
                            {...controllerField}
                            options={field.options}
                            value={controllerField.value}
                            onChange={(selectedOption) => setValue(field.name, selectedOption)}
                          />
                        ) : field.type === 'textarea' ? (
                          <textarea
                            id={field.name}
                            className={`form-control ${errors[field.name] ? 'is-invalid' : ''}`}
                            {...controllerField}
                            style={{ minHeight: '40px', height: '40px' }}
                          />
                        ) : field.type === 'toggle' ? (
                          <div className="form-check form-switch">
                            <input
                              className={`form-check-input ${errors[field.name] ? 'is-invalid' : ''}`}
                              type="checkbox"
                              role="switch"
                              id={field.name}
                              {...controllerField}
                            />
                            <label className="form-check-label" htmlFor={field.name}>
                              {controllerField.value ? 'Yes' : 'No'}
                            </label>
                          </div>
                        ) : field.type === 'multiSelect' ? (
                          <Select
                            {...controllerField}
                            options={field.options}
                            value={watch(field.name)}
                            className={`bg-white z-index-10 w-100 ${errors[field.name] ? 'is-invalid' : ''}`}
                            isMulti
                            placeholder="Select option"
                            getOptionLabel={(option) => option.label || option.title}
                            getOptionValue={(option) => option?.value || option?.id}
                            onChange={(selectedOptions) => setValue(field.name, selectedOptions)}
                            styles={{
                              control: (provided) => ({
                                ...provided,
                                borderColor: errors[field.name] ? 'tomato' : provided.borderColor,
                              }),
                            }}
                          />
                        ) : field.type === 'duration' ? (
                          <DurationPicker
                            maxDuration={86400} // max duration in seconds (24 hours)
                            onChange={(value) => setOvertimeDuration(value)} // Capture the formatted time
                          />
                        ) : (
                          <input
                            type={field.type}
                            id={field.name}
                            className={`form-control ${errors[field.name] ? 'is-invalid' : ''}`}
                            {...controllerField}
                            disabled={field.disabled}
                          />
                        )}
                        {errors[field.name] && <div className="invalid-feedback">This field is required</div>}
                      </>
                    )}
                  />
                </div>
              ))}
            </div>
            <div className="modal-footer border-top-0">
              <button
                type="button"
                className="btn btn-success"
                data-bs-dismiss="modal"
                onClick={() => reset()}
              >
                Cancel
              </button>
              <button type="submit" className="btn btn-primary">
                Confirm
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

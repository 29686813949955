import React, { useEffect, useState } from "react";
import LeaveShowGrid from "../ogComponent/leaveShowGrid";
import Table from "../ogComponent/table";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import Spinner from "../ogComponent/spinner";

export default function SingleUserReport() {
  const [product, setProduct] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [noLeaves, setNoLeaves] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingEmployee, setLoadingEmployee] = useState(true);

  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const startDateQuery = searchParams.get("startDate");
  const endDateQuery = searchParams.get("endDate");

  const [startDate, setStartDate] = useState(startDateQuery || "");
  const [endDate, setEndDate] = useState(endDateQuery || "");

  const showData = {
    firstHeader: "Total Employees",
    firstCount: "1",
    secondHeader: "Attendance",
    secondCount: "N/A",
    thirdHeader: "Leaves",
    thirdCount: product.length.toString(),
  };

  const header = [
    { name: "Si No", label: "#", active: true },
    { name: "date", label: "Date", active: true },
    { name: "day", label: "Day", active: true },
    { name: "leave_type", label: "Leave Type", active: true },
    { name: "status", label: "Status", active: true },
  ];

  const fetchData = async () => {
    setLoading(true);
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/filter_by_date?start_date=${startDate}&end_date=${endDate}&employee_id=${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const filterData = response.data;

      if (filterData.length === 0) {
        setNoLeaves(true);
        setProduct([]);
      } else {
        setNoLeaves(false);

        const formattedData = filterData.map((item, index) => ({
          "Si No": index + 1,
          date: item.start_date || "N/A",
          day: new Date(item.start_date).toLocaleString("en-US", {
            weekday: "long",
          }),
          leave_type: item.leave_type || "N/A",
          status: item.status || "N/A",
          employee_id: item.employee.id,
          id: item.id,
        }));

        setProduct(formattedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchEmployeeDetails = async () => {
    setLoadingEmployee(true);
    const token = localStorage.getItem("accessToken");

    try {
      const response = await axios.get(
        `https://hrmsbackend.prosevo.com/api/account/users-list/${id}`,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      setEmployee(response.data); // Assuming API returns an array
    } catch (error) {
      console.error("Error fetching employee details:", error);
    } finally {
      setLoadingEmployee(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
    fetchEmployeeDetails();
  }, [startDate, endDate]);

  const handleDateChange = (type, value) => {
    if (type === "startDate") {
      setStartDate(value);
      searchParams.set("startDate", value);
    } else if (type === "endDate") {
      setEndDate(value);
      searchParams.set("endDate", value);
    }
    setSearchParams(searchParams);
  };

  const handleRowClick = (item) => {
    navigate(`/user/leaveDetails/${item.employee_id}/${item.id}`);
  };

  if (loading || loadingEmployee) {
    return <Spinner />;
  }

  return (
    <div className="leaveReportContainer w-100 d-flex flex-column p-3 p-md-4 gap-3 gap-md-4">
      <LeaveShowGrid showData={showData} />

      {employee && (
        <div className="empData">
          <p className="text-secondary m-0">
            Name: <span className="text-black fw-semibold">{employee.name}</span>
          </p>
          <p className="m-0 text-secondary">
            Branch:{" "}
            <span className="text-black fw-semibold">
              {employee.branch.branch_name} - {employee.branch.location}
            </span>
          </p>
          <p className="m-0 text-secondary">
            Position:{" "}
            <span className="text-black fw-semibold">{employee.job_position.job_title}</span>
          </p>
          <p className="m-0 text-secondary">
            Phone: <span className="text-black fw-semibold">{employee.phone_number}</span>
          </p>
          <p className="m-0 text-secondary">
            Email: <span className="text-black fw-semibold">{employee.email}</span>
          </p>
        </div>
      )}

      {/* Filter by date */}
      <div className="dateFilterContainer d-flex gap-3 justify-content-between justify-content-sm-end">
        <div className="startDate d-flex flex-column">
          <label
            htmlFor="startDate"
            style={{ fontSize: "clamp(10px, 1.2vw, 13px)" }}
            className="fw-bold"
          >
            Start Date
          </label>
          <input
            id="startDate"
            type="date"
            className="rounded-1 px-2 px-md-3 py-1 bg-white"
            style={{
              border: "2px solid grey",
              fontSize: "clamp(12px, 1.3vw, 15px)",
            }}
            value={startDate}
            onChange={(e) => handleDateChange("startDate", e.target.value)}
          />
        </div>
        <div className="endDate d-flex flex-column">
          <label
            htmlFor="endDate"
            style={{ fontSize: "clamp(10px, 1.2vw, 13px)" }}
            className="fw-bold"
          >
            End Date
          </label>
          <input
            id="endDate"
            type="date"
            className="rounded-1 px-2 px-md-3 py-1 bg-white"
            style={{
              border: "2px solid grey",
              fontSize: "clamp(12px, 1.4vw, 16px)",
            }}
            value={endDate}
            onChange={(e) => handleDateChange("endDate", e.target.value)}
          />
        </div>
      </div>

      {noLeaves ? (
        <div
          className="d-flex justify-content-center align-items-end position-relative"
          style={{ height: "50vh" }}
        >
          <div
            className="position-absolute top-0 start-0 w-100 h-100"
            style={{
              backgroundImage: "url(/assets/file.png)",
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              opacity: 0.3,
            }}
          ></div>
          <p className="m-0 fw-light fs-5 position-relative">
            No Leaves in this Date!
          </p>
        </div>
      ) : (
        <Table header={header} product={product} onRowClick={handleRowClick} />
      )}
    </div>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import CustomAccordion from './CustomAccordion';
import { IconEdit, IconPlus, IconTrash } from '@tabler/icons-react';
import CustomModal from "../ogComponent/modal";
import { Box, Modal } from '@mui/material';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from 'axios';

export default function SubjectDetails() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [courseDetails, setCourseDetails] = useState(null);
    const [showEditModal, setShowEditModal] = useState(false);
    const [combinedCourses, setCombinedCourses] = useState([]);
    const [mentorsOptions, setMentorsOptions] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [formData, setFormData] = useState({
        task_name: '',
        task_description: '',
        refer_link: '',
        total_mark: '',
        time_duration: ''
    });

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        outline: "none",
        borderRadius: '10px',
    };

    const formatText = (text) => {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
    };

    const editModalFields = [
        {
            name: "title",
            label: "Title",
            type: "text",
            value: formatText(courseDetails?.title),
            required: true,
        },
        {
            name: "combined_courses",
            label: "Subjects",
            type: "multiSelect",
            options: combinedCourses || [],
            value: courseDetails?.courses?.map(course => ({
                value: course?.id,
                label: `${course?.title}`,
            })) || [],
            required: true,
        },
        {
            name: "mentors",
            label: "Mentors",
            type: "multiSelect",
            options: mentorsOptions || [],
            value: courseDetails?.mentors?.map(mentor => ({
                value: mentor?.id,
                label: `${mentor?.name}`,
            })) || [],
            required: true,
        },
        {
            name: 'duration',
            label: 'Course Duration (days)',
            type: 'number',
            value: courseDetails?.duration,
            required: true,
        },
        {
            name: 'description',
            label: 'Description',
            value: courseDetails?.description,
            type: 'textarea',
            required: true
        },
    ];

    const handleEditSubmit = async (formData) => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Authentication token not found',
                    life: 3000
                });
                return;
            }

            // Format the data according to the required structure
            const payload = {
                title: formData.title,
                description: formData.description,
                mentors: formData.mentors.map(mentor => mentor.value),
                duration: parseInt(formData.duration),
                combined_courses: formData.combined_courses.map(course => course.value)
            };

            const response = await fetch(`https://hrmsbackend.prosevo.com/api/course/individual-courses/${id}/`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(payload)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Update the local state with the new data
            setCourseDetails(data);

            // Show success message
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Course updated successfully',
                life: 3000
            });

            // Close the modal by updating the state
            setShowEditModal(false);

        } catch (error) {
            console.error('Error updating course:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to update course',
                life: 3000
            });
        }
    };

    const fetchMentors = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const response = await fetch('https://hrmsbackend.prosevo.com/api/account/users-list/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const data = await response.json();
            const formattedData = data.map(mentor => ({
                value: mentor.id,
                label: mentor.name,
            }));
            setMentorsOptions(formattedData); // Set the fetched data to state
        } catch (error) {
            console.error('Error fetching mentors:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to fetch mentors',
                life: 3000,
            });
        }
    };

    useEffect(() => {
        fetchMentors(); // Fetch mentors when the component mounts
    }, []);

    const fetchCombinedCourses = async () => {
        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                throw new Error('Authentication token not found');
            }

            const response = await fetch('https://hrmsbackend.prosevo.com/api/course/combined-courses/', {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

            const data = await response.json();
            const formattedData = data.map(course => ({
                value: course.id,
                label: course.course_name,
            }));
            // console.log("course",formattedData)
            setCombinedCourses(formattedData); // Set the fetched data to state
        } catch (error) {
            console.error('Error fetching combined courses:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to fetch combined courses',
                life: 3000,
            });
        }
    };

    useEffect(() => {
        fetchCombinedCourses(); // Fetch data when the component mounts
    }, []);


    // Fetch course details when component mounts
    useEffect(() => {
        const fetchCourseDetails = async () => {
            try {
                const token = localStorage.getItem('accessToken');
                if (!token) {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Authentication token not found',
                        life: 3000
                    });
                    return;
                }

                const response = await fetch(`https://hrmsbackend.prosevo.com/api/course/individual-courses/${id}/`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                setCourseDetails(data);
                console.log("courseDetails", courseDetails)
            } catch (error) {
                console.error('Error fetching course details:', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to fetch course details',
                    life: 3000
                });
            }
        };

        if (id) {
            fetchCourseDetails();
        }
    }, [id]);

    const Tasks = [
        {
            id: 1,
            task_name: 'Introduction To React',
            course_name: 'Introduction to React',
            Description: 'React introduction',
            student_count: 3,
            course_instructor: { name: 'Aboobacker' },
            course_duration: 1,
            total_mark: 100,
            duration: '1 Day',
        },
        {
            id: 2,
            task_name: 'Introduction Video Watch',
            course_name: 'Video Watch - React Intro',
            Description: 'React introduction 2',
            student_count: 2,
            course_instructor: { name: 'Anagha' },
            course_duration: 1,
            total_mark: 100,
            duration: '1 Day',
        }
    ];

    const handleBodyClick = (id) => {
        navigate(`/user/task/${id}`);
    };

    function capitalize(input) {
        return input
            .split(" ") // Split the string into an array of words
            .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter and append the rest
            .join(" "); // Join the words back into a single string
    }

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files);
        setSelectedFiles((prev) => [...prev, ...files]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const token = localStorage.getItem('accessToken');
            if (!token) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Authentication token not found',
                    life: 3000
                });
                return;
            }

            const formDataToSend = new FormData();
            Object.keys(formData).forEach((key) => {
                formDataToSend.append(key, formData[key]);
            });
            selectedFiles.forEach((file) => {
                formDataToSend.append('files', file);
            });

            const response = await fetch('https://hrmsbackend.prosevo.com/api/employee/tasks/', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                body: formDataToSend
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Task created successfully',
                life: 3000
            });

            setFormData({
                task_name: '',
                task_description: '',
                refer_link: '',
                total_mark: '',
                time_duration: ''
            });
            setSelectedFiles([]);

        } catch (error) {
            console.error('Error submitting form:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to create task',
                life: 3000
            });
        } finally {
            setLoading(false);
        }
    };

    const handleEditClick = () => {
        setShowEditModal(true);
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleConfirmDelete = async () => {
        const token = localStorage.getItem("accessToken");
        try {
            await axios.delete(
                `https://hrmsbackend.prosevo.com/api/course/individual-courses/${id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Handle successful deletion
            setOpenDeleteModal(false);
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: "Subject Deleted Successfully",
                life: 3000,
            });

            // Redirect after 3 seconds
            setTimeout(() => {
                navigate('/user/subcourses/');
            }, 3000);
        } catch (error) {
            console.error("Error deleting branch:", error);
            // Handle error (e.g., show an error message to the user)
        }
    };

    const handleDeleteClick = () => {
        if (courseDetails.student_count > 0) {
            toast.current.show({
                severity: 'warn',
                summary: 'Cannot Delete Branch',
                detail: "This branch contains employees. Please remove employees before deleting.",
                life: 3000,
            });
        } else {
            setOpenDeleteModal(true);
        }
    };

    return (
        <>
            <style>
                {`
                    @media (max-width: 500px) {
                    .modalmainbox {
                    min-width: 75% !important;
                    }
                    }
                `}
            </style>
            <div className="singleSubjectContainer px-md-5 px-3 pt-4 w-100">
                <Toast ref={toast} position="bottom-right" />
                {courseDetails && (
                    <>
                        <h4>{capitalize(courseDetails.title)}</h4>
                        <p className='mb-0'>Duration : {courseDetails.duration} days</p>
                        <p>Description : {courseDetails.description}</p>
                    </>
                )}

                <div className="employeeTableContainer w-100">
                    <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                        <button className="btn btn-success" data-bs-toggle="modal" data-bs-target="#staticBackdrop1">
                            Create Task <IconPlus stroke={2} />
                        </button>
                        <button
                            className="btn btn-outline-primary"
                            onClick={handleEditClick}
                            data-bs-toggle="modal"
                            data-bs-target="#staticBackdrop"
                        >
                            <IconEdit stroke={2} />
                        </button>
                        <button className="btn btn-outline-danger"
                                    onClick={handleDeleteClick}
                                >
                                    <IconTrash stroke={2} />
                                </button>
                    </div>
                </div>

                {/* Custom Accordion for Tasks */}
                <CustomAccordion items={Tasks} onBodyClick={handleBodyClick} />

                {/* Task Creation Modal */}
                <div className="modal fade" id="staticBackdrop1" data-bs-backdrop="static" tabIndex="-1" aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Create New Task</h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <label className="form-label">Task Name *</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="task_name"
                                            value={formData.task_name}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Task Description</label>
                                        <textarea
                                            className="form-control"
                                            name="task_description"
                                            value={formData.task_description}
                                            onChange={handleInputChange}
                                            rows="3"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Reference Link</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="refer_link"
                                            value={formData.refer_link}
                                            onChange={handleInputChange}
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Attach Files</label>
                                        <input
                                            type="file"
                                            className="form-control"
                                            onChange={handleFileChange}
                                            multiple
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Total Mark *</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="total_mark"
                                            value={formData.total_mark}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <label className="form-label">Time Duration (minutes) *</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="time_duration"
                                            value={formData.time_duration}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        disabled={loading}
                                    >
                                        {loading ? 'Creating...' : 'Create Task'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/*Edit Modal*/}
                <CustomModal
                    heading="Edit Course Data"
                    fields={editModalFields}
                    onSubmit={handleEditSubmit}
                    type={"course"}
                />

                {/*delete Modal*/}
                <Modal
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ outline: "none" }}
                >
                    <Box
                        sx={{ ...modalStyle, outline: "none", padding: "25px" }}
                        className="modalmainbox"
                    >
                        <div className="w-100 d-flex align-items-center flex-column">
                            <WarningAmberIcon
                                sx={{ color: "#FFA000", fontSize: 60, width: "100%" }}
                            />
                            <Box
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "clamp(13px, 1.4vw, 17px)",
                                    mb: 2,
                                }}
                            >
                                Confirm Delete
                            </Box>
                            <Box
                                sx={{
                                    color: "#555",
                                    mb: 2,
                                    fontSize: "clamp(12px, 1.2vw, 14px)",
                                    textWrap: "wrap",
                                    textAlign: "center",
                                    paddingX: "10px",
                                }}
                            >
                                Are you sure want to delete this Course?
                            </Box>
                        </div>
                        <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
                            <button
                                className="btn btn-danger"
                                onClick={handleConfirmDelete}
                                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
                            >
                                Confirm
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={handleCloseDeleteModal}
                                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
                            >
                                Cancel
                            </button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </>
    );
}
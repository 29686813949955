import { IconPlus } from '@tabler/icons-react';
import { Toast } from 'primereact/toast';
import React, { useEffect, useRef, useState } from 'react';
import Modal from '../ogComponent/modal';
import { useNavigate } from 'react-router-dom';

export default function Subjects() {
    const toast = useRef(null);
    const [subCourses, setSubCourses] = useState([]);
    const [combinedCourses, setCombinedCourses] = useState([]); // State to store combined courses
    const [showModal, setShowModal] = useState(false);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const navigate = useNavigate();

    const fetchSubCourses = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch("https://hrmsbackend.prosevo.com/api/course/individual-courses/", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const transformedCourses = data.map(course => ({
                    id: course.id,
                    course_name: course.title,
                    course_instructor: { name: `Mentor ${course.mentors[0]}`, id: course.mentors[0] },
                    student_count: 0, // Placeholder; add real data if available
                    course_duration: `${course.duration} Days`,
                }));
                setSubCourses(transformedCourses);
            } else {
                console.error("Failed to fetch courses:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    const fetchCombinedCourses = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch("https://hrmsbackend.prosevo.com/api/course/combined-courses/", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const transformedOptions = data.map(course => ({
                    id: course.id,
                    label: course.course_name, // Friendly name for the dropdown
                }));
                setCombinedCourses(transformedOptions);
            } else {
                console.error("Failed to fetch combined courses:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching combined courses:", error);
        }
    };

    const fetchInstructors = async () => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch("https://hrmsbackend.prosevo.com/api/account/users-list/", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                const options = data.map(instructor => ({
                    value: instructor.id,
                    label: instructor.name,
                }));
                setInstructorOptions(options);
            } else {
                console.error("Failed to fetch instructors:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching instructors:", error);
        }
    };

    useEffect(() => {
        fetchSubCourses();
        fetchCombinedCourses(); // Fetch combined courses
        fetchInstructors();

        if (showModal) {
            const modalElement = document.getElementById('staticBackdrop');
            const modalInstance = new window.bootstrap.Modal(modalElement);
            modalInstance.show();

            modalElement.addEventListener('hidden.bs.modal', () => setShowModal(false));
        }
    }, [showModal]);

    const courseFields = [
        { name: 'title', label: 'Subject Name', type: 'text', required: true },
        { name: 'duration', label: 'Subject Duration (days)', type: 'number', required: true },
        {
            name: 'combined_courses',
            label: 'Courses',
            type: 'multiSelect',
            options: combinedCourses, // Use options from combinedCourses state
            required: false,
        },
        { name: 'mentors', label: 'Subject Instructor', type: 'multiSelect', options: instructorOptions, required: true },
        { name: 'description', label: 'Description', type: 'textarea', required: true },
    ];

    const handleSub_CourseSubmit = async (formData) => {
        const transformedData = {
            title: formData.title,
            description: formData.description,
            mentors: formData.mentors?.map(instructor => instructor.value) || [],
            duration: formData.duration,
            combined_courses: Array.isArray(formData.combined_courses)
                ? formData.combined_courses.map(course => course.id)
                : [], // Ensure combined_courses is an array
        };

        const token = localStorage.getItem('accessToken');

        try {
            const response = await fetch('https://hrmsbackend.prosevo.com/api/course/individual-courses/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(transformedData),
            });

            if (response.ok) {
                console.log("Course added successfully");
                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: "New Course Created!",
                    life: 3000,
                });
                setShowModal(false);
                fetchSubCourses();
            } else {
                console.error("Failed to add course:", response.statusText);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: "Failed to Create Course",
                    life: 3000,
                });
            }
        } catch (error) {
            console.error("Error submitting course data:", error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: "Failed to Create Course",
                life: 3000,
            });
        }
    };

    const handleCourseClick = (id) => {
        navigate(`/user/subcourses/${id}`);
    };

    const handleAddCourse = () => {
        setShowModal(true);
    };

    return (
        <div className="container-fluid px-3 px-md-4 d-flex flex-column gap-3">
            <Toast ref={toast} position="bottom-right" />
            <div className="d-flex flex-column justify-content-start mt-3 mb-1">
                <h5 className="fw-light mb-0">Subjects</h5>
                <p
                    className="align-self-start mb-0"
                    style={{ color: "grey", fontSize: "clamp(10px, 1.1vw, 12px)", fontWeight: '600' }}
                >
                    Total {subCourses.length} Subject{subCourses.length !== 1 ? "s" : ""}
                </p>
            </div>
            <button className="btn btn-success" style={{ width: '150px' }} onClick={handleAddCourse}>
                Add New <IconPlus stroke={2} />
            </button>
            {subCourses.length === 0 ? (
                <div
                    className="d-flex justify-content-center align-items-end position-relative"
                    style={{ height: "50vh" }}
                >
                    <div
                        className="position-absolute top-0 start-0 w-100 h-100"
                        style={{
                            backgroundImage: 'url(/assets/file.png)',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            opacity: 0.3,
                        }}
                    ></div>
                    <p className="m-0 fw-light fs-5 position-relative">No Courses!</p>
                </div>
            ) : (
                <div className="row">
                    {subCourses.map((course) => (
                        <div
                            key={course.id}
                            className="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                            style={{ cursor: "pointer" }}
                            onClick={() => handleCourseClick(course.id)}
                        >
                            <div className="card h-100 px-3 pb-2">
                                <div className="card-body d-flex flex-column py-1 px-0">
                                    <div className="p-2">
                                        <h6
                                            className="card-title mb-0"
                                            style={{
                                                fontSize: 'clamp(12px, 1.2vw, 14px)',
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                width: '100%'
                                            }}
                                            title={course?.course_name}
                                        >
                                            {course?.course_name}
                                        </h6>
                                    </div>
                                    <div
                                        className="rounded-3 px-3 py-2"
                                        style={{ backgroundColor: "#f8f7f7", fontSize: 'clamp(12px, 1.2vw, 14px)' }}
                                    >
                                        {/* <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-person me-2"></i>
                                            {course?.course_instructor?.name}
                                        </p> */}
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-box-seam me-2"></i>
                                            {course?.course_duration}
                                        </p>
                                        <p className="card-text" style={{ marginBottom: '0.5rem' }}>
                                            <i className="bi bi-people me-2"></i>
                                            Students : {course.student_count}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
            <Modal
                heading="Add Subject"
                fields={courseFields}
                onSubmit={handleSub_CourseSubmit}
                type={"course"}
            />
        </div>
    );
}

import { Box, FormControl, MenuItem, Modal, Select } from '@mui/material';
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CustomModal from "../ogComponent/modal";
import { IconEdit, IconTrash } from '@tabler/icons-react';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import axios from 'axios';

export default function CourseDetails() {
    const toast = useRef(null);
    const navigate = useNavigate();
    const [showEditModal, setShowEditModal] = useState(false);
    const [instructorOptions, setInstructorOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [course, setCourse] = useState(null);
    const [subCourses, setSubCourses] = useState([]);
    const { id } = useParams();

    const modalStyle = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        p: 4,
        outline: "none",
        borderRadius: '10px',
    };

    // Fetch course data
    useEffect(() => {
        const fetchCourseData = async () => {
            const token = localStorage.getItem('accessToken');
            try {
                const response = await fetch(`https://hrmsbackend.prosevo.com/api/course/combined-courses/${id}/`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response)
                if (response.ok) {
                    const data = await response.json();
                    setCourse(data);
                } else {
                    console.error("Failed to fetch course:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching course:", error);
            }
        };

        fetchCourseData();
    }, [id]);

    // const Courses = [
    //     {
    //         id: 1,
    //         course_name: "Mern Stack",
    //         course_code: "MERN01",
    //         category: "Day Batch (Normal)",
    //         sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
    //         duration: 180,
    //         start_date: "2024-11-01",
    //         end_date: "2024-11-30",
    //         course_fee: 39990,
    //         course_instructor: { name: "Abbobacker", id: 1 },
    //         description: "Mern full stack dev",
    //         student_count: 15,
    //     },
    //     {
    //         id: 2,
    //         course_name: "Python D-jango",
    //         course_code: "PY01",
    //         category: "Weekend Batch",
    //         sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
    //         duration: 90,
    //         start_date: "2024-12-01",
    //         end_date: "2024-12-30",
    //         course_fee: 19990,
    //         course_instructor: { name: 'Anagha P.A', id: 2 },
    //         description: "Python django backend.",
    //         student_count: 7,
    //     },
    //     {
    //         id: 3,
    //         course_name: "Odoo Erp Dev",
    //         course_code: "ODOO01",
    //         category: "Night Batch",
    //         sub_courses: [{ name: 'Html', id: 1 }, { name: 'Css', id: 2 }, { name: 'Javascript', id: 3 }],
    //         duration: 60,
    //         start_date: "2025-01-01",
    //         end_date: "2025-02-15",
    //         course_fee: 19990,
    //         course_instructor: { name: 'Sreya', id: 3 },
    //         description: "Odoo Erp Development",
    //         student_count: 5,
    //     }
    // ];

    // Fetch sub-courses data
    useEffect(() => {
        const fetchSubCourses = async () => {
            const token = localStorage.getItem('accessToken');
            try {
                const response = await fetch("https://hrmsbackend.prosevo.com/api/course/individual-courses/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setSubCourses(data);
                    console.log("sub", subCourses)
                } else {
                    console.error("Failed to fetch sub-courses:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching sub-courses:", error);
            }
        };

        fetchSubCourses();
    }, []);


    useEffect(() => {
        // Fetch instructor options on component mount
        const fetchInstructors = async () => {
            const token = localStorage.getItem('accessToken');
            try {
                const response = await fetch("https://hrmsbackend.prosevo.com/api/account/users-list/", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    const instructorOptions = data.map(instructor => ({
                        value: instructor.id,
                        label: instructor.name,
                    }));
                    setInstructorOptions(instructorOptions);
                } else {
                    console.error("Failed to fetch instructors:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching instructors:", error);
            }
        };

        fetchInstructors();

        if (showModal) {
            const modalElement = document.getElementById('staticBackdrop');
            const modalInstance = new window.bootstrap.Modal(modalElement);
            modalInstance.show();

            modalElement.addEventListener('hidden.bs.modal', () => setShowModal(false));
        }
    }, [showModal]);


    const formatText = (text) => {
        return text ? text.charAt(0).toUpperCase() + text.slice(1) : "";
    };

    const editModalFields = [
        {
            name: "course_name",
            label: "Course Name",
            type: "text",
            value: formatText(course?.course_name),
            required: true,
        },
        {
            name: "sub_courses",
            label: "Sub Courses",
            type: "multiSelect",
            options: subCourses || [],
            value: course?.courses?.map(course => ({
                value: course?.id,
                label: `${course?.title}`,
            })) || [],
            required: true,
        },
        {
            name: 'duration', label: 'Course Duration (days)', type: 'number', value: course?.duration, required: true
        },
        {
            name: 'fee', label: 'Course Fee', type: 'number', value: course?.fee, required: true
        },
        {
            name: 'description', label: 'Description', value: course?.description, type: 'textarea', required: true
        },
    ];

    const handleEditClick = () => {
        setShowEditModal(true);
    };

    const handleDeleteClick = () => {
        if (course.student_count > 0) {
            toast.current.show({
                severity: 'warn',
                summary: 'Cannot Delete Branch',
                detail: "This branch contains employees. Please remove employees before deleting.",
                life: 3000,
            });
        } else {
            setOpenDeleteModal(true);
        }
    };

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal(false);
    };

    const handleEditSubmit = async (data) => {
        const token = localStorage.getItem('accessToken');
        try {
            const response = await fetch(`https://hrmsbackend.prosevo.com/api/course/combined-courses/${id}/`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    course_name: data.course_name,
                    description: data.description,
                    sub_courses: data.sub_courses,
                    duration: data.duration,
                    fee: data.fee,
                }),
            });

            if (response.ok) {
                const updatedCourse = {
                    course_name: data.course_name,
                    description: data.description,
                    sub_courses: data.sub_courses,
                    duration: data.duration,
                    fee: data.fee,
                };

                // Update local state to reflect changes
                setCourse((prevCourse) => ({
                    ...prevCourse,
                    ...updatedCourse,
                }));

                toast.current.show({
                    severity: 'success',
                    summary: 'Success',
                    detail: 'Course updated successfully',
                    life: 3000,
                });

                setShowEditModal(false); // Close modal after successful submission
            } else {
                console.error("Failed to update course:", response.statusText);
            }
        } catch (error) {
            console.error("Error updating course:", error);
        }
    };

    const handleConfirmDelete = async () => {
        const token = localStorage.getItem("accessToken");
        try {
            await axios.delete(
                `https://hrmsbackend.prosevo.com/api/course/combined-courses/${id}/`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // Handle successful deletion
            setOpenDeleteModal(false);
            toast.current.show({
                severity: 'success',
                summary: 'Success',
                detail: "Branch Deleted Successfully",
                life: 3000,
            });

            // Redirect after 3 seconds
            setTimeout(() => {
                navigate('/user/courses/');
            }, 3000);
        } catch (error) {
            console.error("Error deleting branch:", error);
            // Handle error (e.g., show an error message to the user)
        }
    };




    return (
        <>
            <style>
                {`
                    @media (max-width: 500px) {
                    .modalmainbox {
                    min-width: 75% !important;
                    }
                        }
                `}
            </style>
            <div className="singleCourseContainer px-md-5 px-3 pt-4 w-100">
                <Toast ref={toast} position="bottom-right" />
                {course ? (
                    <>
                        <h4>{course.course_name}</h4>
                        <p
                            className="mb-0 fw-bolder"
                            style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
                        >
                            Duration : {" "}
                            <span
                                className="ms-2 fw-lighter"
                                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
                            >
                                {`${course.duration} Days` || 'N/A'}
                            </span>
                        </p>
                        <p
                            className="mb-0 fw-bolder"
                            style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
                        >
                            Students : {" "}
                            <span
                                className="ms-2 fw-lighter"
                                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
                            >
                                {course?.student_count || 0}
                            </span>
                        </p>
                        <p
                            className="mb-0 fw-bolder"
                            style={{ fontSize: "clamp(12px, 1.2vw, 13px)" }}
                        >
                            Description : {" "}
                            <span
                                className="ms-2 fw-lighter"
                                style={{ fontSize: "clamp(14px, 1.3vw, 16px)" }}
                            >
                                {course?.description || "N/A"}
                            </span>
                        </p>

                        <div className="employeeTableContainer w-100">
                            <div className="d-flex justify-content-end mb-3 gap-2 gap-md-3">
                                <button
                                    className="btn btn-outline-primary"
                                    onClick={handleEditClick}
                                    data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop"
                                >
                                    <IconEdit stroke={2} />
                                </button>
                                <button className="btn btn-outline-danger"
                                    onClick={handleDeleteClick}
                                >
                                    <IconTrash stroke={2} />
                                </button>
                            </div>
                        </div>
                    </>
                ) : (
                    <p>Department not found.</p>
                )}
                <CustomModal
                    heading="Edit Course Data"
                    fields={editModalFields}
                    onSubmit={handleEditSubmit}
                    type={"course"}
                />
                <Modal
                    open={openDeleteModal}
                    onClose={handleCloseDeleteModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{ outline: "none" }}
                >
                    <Box
                        sx={{ ...modalStyle, outline: "none", padding: "25px" }}
                        className="modalmainbox"
                    >
                        <div className="w-100 d-flex align-items-center flex-column">
                            <WarningAmberIcon
                                sx={{ color: "#FFA000", fontSize: 60, width: "100%" }}
                            />
                            <Box
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: "clamp(13px, 1.4vw, 17px)",
                                    mb: 2,
                                }}
                            >
                                Confirm Delete
                            </Box>
                            <Box
                                sx={{
                                    color: "#555",
                                    mb: 2,
                                    fontSize: "clamp(12px, 1.2vw, 14px)",
                                    textWrap: "wrap",
                                    textAlign: "center",
                                    paddingX: "10px",
                                }}
                            >
                                Are you sure want to delete this Course?
                            </Box>
                        </div>
                        <div className="w-100 d-flex justify-content-between flex-row-reverse gap-3">
                            <button
                                className="btn btn-danger"
                                onClick={handleConfirmDelete}
                                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
                            >
                                Confirm
                            </button>
                            <button
                                className="btn btn-secondary"
                                onClick={handleCloseDeleteModal}
                                style={{ fontSize: "clamp(12px, 1.2vw, 15px)" }}
                            >
                                Cancel
                            </button>
                        </div>
                    </Box>
                </Modal>

            </div>
        </>
    )
}

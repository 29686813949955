import React, { useState, useEffect } from 'react';
import Table from '../ogComponent/table';
import Spinner from '../ogComponent/spinner';
import axios from 'axios';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useNavigate } from 'react-router-dom';
import { Switch, Modal, Box, Button, Typography } from '@mui/material';

export default function AllAttendance() {
  const [product, setProduct] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [modalOpen, setModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null); // To hold data for the modal
  const navigate = useNavigate();

  const header = [
    { name: 'si_no', label: '#', active: true },
    { name: 'employee_code', label: 'ID', active: true },
    { name: 'name', label: 'Name', active: true },
    { name: 'date', label: 'Date', active: true },
    // { name: 'day', label: 'Day', active: true },
    { name: 'clock_in', label: 'Check-In', active: true },
    { name: 'clock_out', label: 'Check-Out', active: true },
    { name: 'action', label: 'Action', active: true },
  ];

  const fetchAttendanceAndLeaveData = async (start, end) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('accessToken');

      const [attendanceResponse, leaveResponse] = await Promise.all([
        axios.get('https://hrmsbackend.prosevo.com/api/attendance/attendance/', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            start_date: start.format('YYYY-MM-DD'),
            end_date: end.format('YYYY-MM-DD'),
          },
        }),
        axios.get('https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/filter_by_date/', {
          headers: { Authorization: `Bearer ${token}` },
          params: {
            start_date: start.format('YYYY-MM-DD'),
            end_date: end.format('YYYY-MM-DD'),
          },
        }),
      ]);

      const attendanceData = attendanceResponse.data.map((item, index) => ({
        ...item,
        si_no: index + 1,
        employee_code: item.employee.employee_code || 'N/A',
        name: (
          <td className="d-flex gap-3 align-items-center px-0">
            <img
              src={item.employee.profile_photo || '/assets/user.png'}
              alt="user img"
              style={{ width: '25px', height: '25px', borderRadius: '50%' }}
            />
            <div>{item.employee?.name || 'N/A'}</div>
          </td>
        ),
        date: dayjs(item.date).format('YYYY-MM-DD'),
        // day: dayjs(item.date).format('dddd'),
        clock_in: item.clock_in || 'N/A',
        clock_out: item.clock_out || 'N/A',
        action: (
          <Switch
            checked={item.attendance_leave_status === 'Present'}
            onClick={(e) => handleSwitchClick(e, item)}
          />
        ),
      }));

      const leaveData = leaveResponse.data.map((item, index) => ({
        ...item,
        si_no: attendanceData.length + index + 1,
        employee_code: item.employee.employee_code || 'N/A',
        name: (
          <td className="d-flex gap-3 align-items-center px-0">
            <img
              src={item.employee.profile_photo || '/assets/user.png'}
              alt="user img"
              style={{ width: '25px', height: '25px', borderRadius: '50%' }}
            />
            <div>{item.employee.name || 'N/A'}</div>
          </td>
        ),
        date: dayjs(item.start_date).format('YYYY-MM-DD'),
        // day: dayjs(item.start_date).format('dddd'),
        clock_in: '-',
        clock_out: '-',
        action: (
          <Switch
            checked={item.attendance_leave_status === 'Present'}
            onClick={(e) => handleSwitchClick(e, item)}
          />
        ),
      }));

      setProduct([...attendanceData, ...leaveData]);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAttendanceAndLeaveData(startDate, endDate);
  }, []);

  const handleDateChange = (newStartDate, newEndDate) => {
    if (newStartDate) setStartDate(newStartDate);
    if (newEndDate) setEndDate(newEndDate);

    fetchAttendanceAndLeaveData(newStartDate || startDate, newEndDate || endDate);
  };

  const handleRowClick = (item) => {
    navigate(`/user/dayList/${item?.employee?.id}`);
  };

  const handleSwitchClick = (e, item) => {
    e.stopPropagation();
    setCurrentItem(item);
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setCurrentItem(null);
  };

  const handleModalConfirm = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      console.log("item",currentItem)
      await axios.post(
        'https://hrmsbackend.prosevo.com/api/leavemanagement/leaves/toggle_leave_status/',
        {
          emp_id: currentItem.employee.id,
          date: currentItem.date || currentItem.start_date,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      fetchAttendanceAndLeaveData(startDate, endDate); // Refresh data
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
      handleModalClose();
    }
  };

  return (
    <div className="AttendanceContainer w-100 h-100 px-3 px-lg-4 pt-3">
      <h3 className="pt-2">All Attendance</h3>
      <div className="accordion-body pb-3 w-100">
        <div className="d-flex gap-3 align-items-center justify-content-end">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Start Date"
              className="MuidatePicker"
              value={startDate}
              onChange={(newValue) => handleDateChange(newValue, null)}
            />
            <DatePicker
              label="End Date"
              className="MuidatePicker"
              value={endDate}
              onChange={(newValue) => handleDateChange(null, newValue)}
            />
          </LocalizationProvider>
        </div>
      </div>

      {loading ? (
        <Spinner />
      ) : product.length > 0 ? (
        <Table header={header} product={product} onRowClick={handleRowClick} />
      ) : (
        <div>No Attendance or Leave Data!</div>
      )}

      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 1,
          }}
        >
          <Typography variant="h6" component="h2">
            {currentItem?.attendance_leave_status === 'Present'
              ? 'Change attendance to Leave?'
              : 'Change attendance to Present?'}
          </Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button variant="outlined" onClick={handleModalClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleModalConfirm}>
              OK
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

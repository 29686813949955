import React, { useState } from 'react';

export default function CustomAccordion({ items, onBodyClick }) {
    const [expandedId, setExpandedId] = useState(null);

    const toggleAccordion = (id) => {
        setExpandedId(expandedId === id ? null : id);
    };

    // Internal styles for animations that can't be handled by Tailwind
    const styles = {
        accordionBody: {
            overflow: 'hidden',
            maxHeight: '0',
            transition: 'max-height 0.3s ease-out, opacity 0.3s ease-out',
            opacity: '0'
        },
        accordionBodyExpanded: {
            maxHeight: '200px', // Adjust based on your content
            opacity: '1'
        },
        accordionContent: {
            transform: 'translateY(-10px)',
            transition: 'transform 0.3s ease-out'
        },
        accordionContentExpanded: {
            transform: 'translateY(0)'
        }
    };

    return (
        <div className="w-full">
            {items.map((item) => (
                <div key={item.id} className="mb-2">
                    <div 
                        className="p-3 bg-secondary text-white cursor-pointer transition-colors duration-300 ease-in-out hover:bg-blue-700 relative"
                        onClick={() => toggleAccordion(item.id)}
                        style={{cursor:'pointer'}}
                    >
                        <h6 className="m-0">{item.course_name}</h6>
                        {/* <span 
                            className={`absolute right-4 top-1/2 -translate-y-1/2 transition-transform duration-300 ${
                                expandedId === item.id ? 'rotate-180' : 'rotate-0'
                            }`}
                        >
                            ▼
                        </span> */}
                    </div>
                    <div 
                        style={{
                            ...styles.accordionBody,
                            ...(expandedId === item.id && styles.accordionBodyExpanded)
                        }}
                    >
                        <div 
                            className="border border-t-0 cursor-pointer"
                            onClick={() => onBodyClick(item.id)}
                            style={{
                                ...styles.accordionContent,
                                ...(expandedId === item.id && styles.accordionContentExpanded)
                            }}
                        >
                            <div className="p-3">
                                <p className="mb-2">
                                    <strong>Instructor:</strong> {item.course_instructor?.name}
                                </p>
                                <p className="mb-2">
                                    <strong>Duration:</strong> {item.course_duration} Days
                                </p>
                                <p className="mb-2">
                                    <strong>Students:</strong> {item.student_count}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}